<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted(){
    let source = this.$cookie.get("source")
    //有业务线
    if(this.$cookie.get("source")){
      console.log(this.$route)
      if(this.$route.path!=`/home/${source}`){
        //跳转到当前业务线首页
        this.$router.push(`/home/${source}`)
      }
    }else{
      this.$router.push(`/home/meiyan`)
    }
  },
  
}
</script>

<style scoped lang="scss">

</style>
